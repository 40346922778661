import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import OwowLogo from '../../Assets/Icons/Logo_Horizontal_purple.png';
import { FaArrowRight } from "react-icons/fa";
import { MdErrorOutline } from "react-icons/md";
import { FiClock } from "react-icons/fi";
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import Helper from '../../utils/helper';

import startRecLogo from '../../Assets/Icons/startRecording.png';
import stopRecLogo from '../../Assets/Icons/stopRecording.png';
import { GPTQuestion, skData } from '../../types';
import { SKILL_TYPES } from '../../utils/constant';
import LottieAnimation from '../../Components/LottieAnimation';

const pythonServiceBaseUrl = process.env.REACT_APP_GPT_SERVER_BASE_URL || '';
const WS_URL = process.env.REACT_APP_GPT_SERVER_SOCKET_URL || '';

declare global {
    interface Window {
        SpeechRecognition: any;
        webkitSpeechRecognition: any;
    }

    interface SpeechRecognitionEvent extends Event {
        readonly results: SpeechRecognitionResultList;
    }

    // Extend the SpeechSynthesisVoice interface to include a gender property
    interface SpeechSynthesisVoice {
        gender?: 'female' | 'male';
    }
}


const EmployeeNewGPTVetting = () => {

    const location = useLocation();
    const { user } = useAuth();
    const navigate = useNavigate();
    const helper = Helper.getInstance();

    const [questions, setQuestions] = useState<GPTQuestion[]>([]);
    const [assesmentId, setAssesmentId] = useState<number>(); // Timer state
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [progress, setProgress] = useState(0);
    const [transcripts, setTranscripts] = useState<string[]>([]);
    const [ws, setWs] = useState<WebSocket | null>(null);
    const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
    const [mediaRecognition, setMediaRecognition] = useState<any>(null);
    const [isRecording, setIsRecording] = useState(false);
    const [transcript, setTranscript] = useState<string>('');
    const [timer, setTimer] = useState<number>(0); // Timer state
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
    const [loading2, setLoading2] = useState<boolean>(false);
    const [isSpeaking, setIsSpeaking] = useState(false);
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const prevUser = useRef<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {

        const getQuestions = async (skills: skData[]) => {
            try {
                const reqBody = {
                    coreSkills: skills.filter((sk) => sk.skillType == SKILL_TYPES.MAIN),
                    additionalSkills: skills.filter((sk) => sk.skillType == SKILL_TYPES.ADDITIONAL)
                }
                const response = await axios.post(`${pythonServiceBaseUrl}/generate_questions`, reqBody);
                if (response.data && response.data.questions) {
                    const noQueRemoved = response.data.questions.filter((q: GPTQuestion) => q.text !== '');
                    setQuestions(noQueRemoved);
                    setAssesmentId(response.data.assesmentId || 1);
                    playAudioInBackground(noQueRemoved[0].audio_url);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                speak("Failed to Load your Assesment, Please Try Again", false);
                toast.error("Failed to Load your Assesment, Please Try Again");
                console.error('Error fetching questions:', error);
            }
        };

        if (!location.state || !location.state.skills || !location.state.skills.length) {
            navigate('/candidate-gpt-vetting-page', { replace: true });
        } else if (user && user !== prevUser.current && mediaRecorder) {
            getQuestions(location.state.skills);
            prevUser.current = user;
        }
    }, [user, mediaRecorder]);


    // Establish WebSocket connection
    useEffect(() => {
        const connectWebSocket = () => {
            const socket = new WebSocket(WS_URL);

            socket.onopen = () => {
                console.log('WebSocket connection established');
            };

            // socket.onmessage = (event) => {
            //     const data = JSON.parse(event.data);
            //     if (data.transcription) {
            //         setTranscript((prev) => prev + data.transcription + ' ');
            //         setTranscripts([...transcripts, transcript]);
            //     }
            // };

            socket.onclose = () => {
                console.log('WebSocket connection closed, attempting to reconnect...');
                setTimeout(connectWebSocket, 5000); // Retry after 5 seconds
            };

            socket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            setWs(socket);
        };

        connectWebSocket();

        return () => {
            ws?.close();
        };
    }, []);

    // Initialize MediaRecorder and handle audio data
    useEffect(() => {
        let recorder: MediaRecorder | null = null;
        let recognition: any;

        const handleSuccess = (stream: MediaStream) => {
            recorder = new MediaRecorder(stream);

            const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
            recognition = new SpeechRecognition();
            setTranscript('');

            recognition.continuous = true;
            recognition.interimResults = true;
            recognition.lang = 'en-IN'; // Set to Indian English

            recognition.onresult = (event: SpeechRecognitionEvent) => {
                const transcriptData = Array.from(event.results)
                    .map(result => result[0].transcript)
                    .join('');

                if (transcriptData.trim() !== '') {
                    // console.log('User said:', transcriptData);
                    setTranscript(transcriptData);
                }
            };

            // Start sending audio data when it's available
            recorder.ondataavailable = (event) => {
                console.log(event.data);
                if (ws) {
                    // if (ws && ws.readyState === WebSocket.OPEN) {
                    ws.send(event.data);
                }
            };

            // Set the recorder and recognition in state
            setMediaRecorder(recorder);
            setMediaRecognition(recognition);

            // Optionally, start recognition immediately if required
            recognition.start();
        };

        // Request access to the microphone
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(handleSuccess)
            .catch(error => {
                console.error('Error accessing media devices.', error);
            });

        // Cleanup function on component unmount
        return () => {
            // Stop the media recorder if it exists
            if (recorder) {
                recorder.stop();
                recorder.stream.getTracks().forEach(track => track.stop());
            }

            // Stop the speech recognition if it exists
            if (recognition) {
                recognition.stop();
            }
            // Reset states
            setMediaRecorder(null);
            setMediaRecognition(null);
        };
    }, [ws]); // Empty dependency array to run on mount/unmount only


    useEffect(() => {
        if (intervalId) clearInterval(intervalId); // Clear any existing interval

        if (questions.length) {
            const id = setInterval(() => {
                setTimer((prev) => {
                    if (prev >= 120) {
                        stopRecording(); // Stop the timer after 2 minutes
                        return 120;
                    }
                    return prev + 1;
                });
            }, 1000); // Update timer every second

            setIntervalId(id);

            return () => clearInterval(id);
        }
        // Cleanup interval on unmount
    }, [questions, currentQuestionIndex]);

    const toggleRecording = () => {
        if (isRecording) {
            stopRecording();
        }
        // else {
        //     startRecording();
        // }
    };

    const startRecording = () => {
        if (mediaRecorder && mediaRecorder.state === 'inactive') {
            mediaRecorder.start();
            setIsRecording(true);
        }

        if (mediaRecognition) mediaRecognition.start();
        setTranscript('');
    };

    const stopRecording = () => {
        if (mediaRecorder && mediaRecorder.state === 'recording') {
            mediaRecorder.stop();
            // if (ws) ws.send(JSON.stringify({ event: 'stop_recording' }));
        }
        if (mediaRecognition) mediaRecognition.stop();
        setIsRecording(false);
        // Clear the timer when recording stops
        if (intervalId) {
            clearInterval(intervalId);
            setIntervalId(null);
        }
        setTimer(0);
        handleNextButton();
    };

    const handleNextButton = () => {
        setProgress((prev) => prev + 100 / questions.length);
        setTranscripts([...transcripts, transcript]);
        setIsRecording(false);

        if (currentQuestionIndex < questions.length - 1) {
            playAudioInBackground(questions[currentQuestionIndex + 1].audio_url);
            setCurrentQuestionIndex((prev) => prev + 1);
        } else {
            setIsSpeaking(true);
            // setCurrentQuestionIndex((prev) => prev + 1);
            getResult();
            // skipResultFlow();
        }
    };

    const skipResultFlow = () => {
        try {
            ws?.close();
        } catch (error) {
            console.log(error);
        }
        setTimeout(() => {
            navigate('/candidate-gpttest-end', { replace: true, state: { result: true } });
        }, 100);
    };

    const getResult = async () => {
        try {
            setLoading(true);
            const skills: skData[] = location.state.skills;

            const response = await axios.post(`${pythonServiceBaseUrl}/generate_result`, {
                employeeId: user?.id || 1,
                questions: questions.map((q: GPTQuestion) => q.text),
                answers: transcripts,
                coreSkills: (skills.filter((sk: skData) => sk.skillType == SKILL_TYPES.MAIN)).map((el) => el.skill),
                additionalSkills: (skills.filter((sk: skData) => sk.skillType == SKILL_TYPES.ADDITIONAL)).map((el) => el.skill)
                // assesmentId
            });
            if (response.data) skipResultFlow();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toast.error("Failed to Generate Result, Please Try Again");
            console.error('Failed to Generate Result: ', error);
        }
    };

    // const saveTest = async (result: number) => {
    //     setLoading2(true);
    //     const bData = {
    //         skill: location.state.skill,
    //         experience: Number(location.state.exp),
    //         queAnswers: questions,
    //         type: Number(location.state.type),
    //         result
    //     };

    //     try {
    //         const res_data = await helper.postReq(helper.POST.CREATE_RESULT, bData);
    //         const { data } = res_data.data;

    //         if (data) navigate('/candidate-gpttest-end', { replace: true, state: { result: true } });
    //         setLoading2(false);
    //     } catch (error) {
    //         console.log(error);
    //         setLoading2(false);
    //     }
    // };

    // const speak = (data: string, isQuestion: boolean = true) => {
    //     if ('speechSynthesis' in window) {
    //         const textData = isQuestion ? `Question Number ${data}` : data
    //         const utterance = new SpeechSynthesisUtterance(textData);
    //         window.speechSynthesis.speak(utterance);
    //     } else {
    //         toast.error('Sorry, your browser does not support text-to-speech.');
    //     }
    // };

    const speak = (text: string, isQuestion: boolean = true): void => {
        // if (isQuestion) setTranscript('');
        // const textData = isQuestion ? `Question Number ${text}` : text
        const utterance = new SpeechSynthesisUtterance(text);

        // Get the list of available voices
        const voices = window.speechSynthesis.getVoices();

        // Find a female, human-like voice (this may vary by browser)
        // console.log(voices);
        const femaleVoice = voices.find(voice =>
            voice.name.includes('Google UK English Female') ||
            voice.name.includes('Google US English Female') ||
            voice.name.includes('Microsoft Zira Desktop') ||
            (voice.gender === 'female' && voice.localService)
        );

        // Set the selected voice
        if (femaleVoice) {
            utterance.voice = femaleVoice;
        }

        // Set additional options
        utterance.pitch = 1.2;  // Slightly higher pitch for a more natural female voice
        utterance.rate = 1;     // Normal speaking rate
        utterance.volume = 1;   // Full volume

        // Speak the text
        // setIsSpeaking(true);
        // Re-enable the button when the speech ends
        // utterance.onend = () => {
        //     setIsSpeaking(false);
        //     if (isQuestion) startRecording();
        // };

        window.speechSynthesis.speak(utterance);
    };

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [transcripts.length]);


    const handleCopy = (event: any) => {
        event.preventDefault();
        toast.error('Copying text is not allowed.');
        speak('Copying text is not allowed', false);
    };

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                speak("Please don't switch tabs or minimize the window!", false);
                alert("Please don't switch tabs or minimize the window!");
                // You can also add additional logic here, such as logging out the user
            }
        };

        const handleBlur = () => {
            alert("Please stay on this tab!");
            speak("Please stay on this tab!", false);
            // You can add additional logic here as well
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        // window.addEventListener('blur', handleBlur);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            // window.removeEventListener('blur', handleBlur);
        };
    }, []);

    const playAudioInBackground = (audioUrl: string): void => {
        setIsSpeaking(true);
        const audio = new Audio(audioUrl);
        audio.play();
        audio.addEventListener('ended', () => {
            console.log('Audio playback completed');
            setTimeout(() => {
                setIsSpeaking(false);
                startRecording();
            }, 10);
        });
    };

    return (
        <section className='main-ashonrd-test'>
            {loading ? <LottieAnimation /> : (
                <div className='container'>
                    <div className='text-center pt-3'>
                        <img src={OwowLogo} className='img-fluid' alt="Owow Logo" />
                    </div>
                    <div className="row mt-3">
                        <div className="col-xl-8 flex-column d-flex grid-margin stretch-card">
                            <div className="row flex-grow">
                                <div className="col-sm-12 grid-margin stretch-card">
                                    <div className="card card-cst-new-dsh">
                                        <div className="card-header card-header-cst">
                                            {questions.length > 0 && <div className='d-flex justify-content-between align-items-center'>
                                                <strong>Question : {currentQuestionIndex + 1}</strong>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div style={{ width: '100px', backgroundColor: 'lightgray', height: '6px', position: 'relative' }}>
                                                        <div className='dyna-pro-bar-cst' style={{ width: `${progress}%`, height: '100%', backgroundColor: '#492A99', position: 'absolute' }}></div>
                                                    </div>
                                                    <strong>&nbsp;{progress}%</strong>
                                                </div>
                                                <strong><FiClock /> {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}</strong>
                                            </div>}
                                        </div>
                                        <div className="card-body card-body-cst">
                                            <div className="card card-cst-dashbord ">
                                                <div className="card-body cst-card-body">
                                                    <h4 className="text-center" onCopy={handleCopy}>{questions[currentQuestionIndex].text}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 stretch-card mt-4">
                                    <div className="card card-cst-new-dsh">
                                        <div className="card-header card-header-cst">
                                            <strong>Record Your Answer</strong>
                                        </div>
                                        <div className="card-body card-body-cst">
                                            <div className="card card-cst-dashbord ">
                                                <div className="card-body cst-card-body text-center">
                                                    {/* <button
                                                    className={`btn ${isRecording ? 'btn-danger' : 'btn-primary'}`}
                                                    onClick={toggleRecording}
                                                >
                                                    {isRecording ? 'Stop Recording' : 'Start Recording'}
                                                </button> */}
                                                    {questions.length > 0 && <img
                                                        src={isRecording ? stopRecLogo : startRecLogo}
                                                        onClick={toggleRecording}
                                                        alt={isRecording ? 'Stop Recording' : 'Start Recording'} className='img-fluid'
                                                        style={{ maxHeight: '150px', maxWidth: '150px', cursor: 'pointer' }}
                                                    />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 d-flex grid-margin stretch-card">
                            <div className="card card-cst-new-dsh">
                                <div className="card-header card-header-cst">
                                    <div className='d-flex justify-content-between'>
                                        <strong>Interview Transcript</strong>
                                        <div className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                onChange={() => console.log("A")}
                                                checked
                                                role="switch"
                                                id="flexSwitchCheckChecked"
                                            />
                                            <label className="form-check-label">Auto Scroll</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body card-body-cst">
                                    <div className="chat-content-scroll-area scrollbar scroller">
                                        {/* {transcript && (
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className='flex-1 d-flex justify-content-start mb-2'>
                                                    <div className="chat-message-left p-3">
                                                        <h6>AI Interviewer</h6>
                                                        <div>{questions[currentQuestionIndex]}</div>
                                                    </div>
                                                </div>
                                                <div className='flex-1 d-flex justify-content-end mb-2'>
                                                    <div className="chat-message-right p-3">
                                                        <h6>{user?.name || 'Candidate'}</h6>
                                                        <div>{transcript}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )} */}
                                        {transcripts.map((transcriptData, index) => (
                                            <div className="row" key={index}>
                                                <div className="col-sm-12">
                                                    <div className='flex-1 d-flex justify-content-start mb-2'>
                                                        <div className="chat-message-left p-3">
                                                            <h6>AI Interviewer</h6>
                                                            <div>{questions[index].text}</div>
                                                        </div>
                                                    </div>
                                                    <div className='flex-1 d-flex justify-content-end mb-2'>
                                                        <div className="chat-message-right p-3">
                                                            <h6>{user?.name || 'Candidate'}</h6>
                                                            <div>{transcriptData}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div ref={messagesEndRef} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8 col-12 mt-4 pb-3'>
                        <div className='px-3'>
                            <div className="d-flex flex-wrap align-items-center justify-content-md-between justify-content-center">
                                <h6 className='align-items-center mb-2'>
                                    <MdErrorOutline style={{ color: 'red' }} />
                                    Please do not refresh the page or you will lose your recorded data
                                </h6>
                                <button
                                    className='btn create-account-btn-cst px-5 d-flex align-items-center justify-content-center mt-3 mt-md-0'
                                    onClick={stopRecording}
                                    disabled={isSpeaking}
                                >
                                    {currentQuestionIndex == questions.length - 1 ? 'Submit' : 'Next'} <FaArrowRight className='ml-2' />
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            )}
        </section>
    );
};

export default EmployeeNewGPTVetting;
