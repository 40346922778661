import React, { useEffect, useState } from 'react'
import { PasswordValidation, User, UserPersonalDetails } from '../../types';
import Helper from '../../utils/helper';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

import { customStyles, genders } from '../../utils/constant';

import Select from 'react-select';
import { BsCalendarFill, BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';
import useAuth from '../../hooks/useAuth';
import CountryIconOne from '../../Assets/CountryIcons/in.png'
import CountryIconFoure from '../../Assets/CountryIcons/us.png'
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import Logo_Horizontal_purpleImage from '../../Assets/Icons/Logo_Horizontal_purple.png'
import Logo_Horizontal_WhiteImage from '../../Assets/Icons/Logo_Horizontal_White.png'
const phoneNumbers = [
    { value: "INDIA", label: "+91" },
    { value: "USA", label: "+1" },
];



const FirstPersonalDetailsComponent = () => {

    const helper = Helper.getInstance();
    const navigate = useNavigate()
    const location = useLocation();
    const [verifying, setVerifying] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [isPassValid, setIsPassValid] = useState<boolean>(true);
    const { login } = useAuth();
    const [selectedCountryCode, setSelectedCountryCode] = useState('+91');
    const [selectedCountryFlag, setSelectedCountryFlag] = useState(CountryIconOne);
    // const [selectedDay, setSelectedDay] = useState(null);
    // const [startDate, setStartDate] = useState(new Date());

    const handleCountryCodeChange = (code: any, flag: any) => {
        setSelectedCountryCode(code);
        setSelectedCountryFlag(flag);
        setUser({ ...user, countryCode: code });
    }

    const initialValue: User = {
        id: 1,
        email: '',
        name: "",
        phone: "",
        dob: "",
        jobTitle: "",
        password: ""
    };

    const [user, setUser] = useState<User>(initialValue);
    // const [OTPToken, setOTPToken] = useState('');
    const [OTP, setOTP] = useState<any[]>([null, null, null, null]);

    const initPassVal: PasswordValidation = {
        hasNumber: false,
        hasSpecialChar: false,
        hasUppercase: false,
        hasLowercase: false,
        isMinLength: false,
    };

    const [passValidation, setPassValidation] = useState<PasswordValidation>(initPassVal);

    useEffect(() => {
        if (!location.state || !location.state.customer) navigate('/employer-login', { replace: true });
        else {
            // login('', '', location.state.customer);
            if (location.state.customer.dob) {
                try {
                    location.state.customer.dob = (new Date(location.state.customer.dob)).toISOString().split('T')[0];
                } catch (error) {
                    console.log(error);
                }
            };
            setUser(location.state.customer);
        }
    }, []);

    // const getOTP = async () => {
    //     // if (OTPToken) return toast.success('OTP Sent Successfully');;

    //     setVerifying(true);
    //     try {
    //         if (!user.phone) return;

    //         const res_data = await helper.postReq(helper.POST.GEN_OTP, { phone: `${selectedCountryCode}${user.phone}`, type: 1 });
    //         const { data } = res_data.data;

    //         if (data) {
    //             setOTPToken(data);
    //             toast.success('OTP Sent Successfully');
    //         }
    //         else throw new Error("Failed");

    //     } catch (error) {
    //         console.log(error);
    //         toast.error('Invalid Phone Number');
    //     }
    //     setVerifying(false);
    // };

    const addUserDetails = async () => {
        try {
            const userDat = {
                name: user.name,
                phone: user.phone,
                gender: user.gender,
                dob: user.dob,
                jobTitle: user.jobTitle,
                password: user.password
            };

            const res_data = await helper.postReq(helper.POST.ADD_PERSONAL_DETAILS, userDat);
            const { data } = res_data.data;
            setLoading(false);
            if (data.customer) {
                if (user.isCollaborator) {
                    navigate("/employer-congratulations", { replace: true, state: { customer: data.customer } })
                } else {
                    const state = {
                        customer: data.customer,
                        company: data.company
                    }
                    navigate('/employer-company-details', { replace: true, state });
                }
            }

            else throw new Error("Failed");
        } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error('Add Personal Details Failed');
        }
        setLoading(false);
    }

    const verifyOTP = async (auto: boolean = false) => {
        addUserDetails();
        // try {
        //     if (!OTPToken) return;

        //     const enteredOTP = ((OTP.join()).replaceAll(',', '')).trim();
        //     // console.log(enteredOTP);

        //     // if (enteredOTP === '0000') await addUserDetails();
        //     // else {
        //         const res_data = await helper.postReq(helper.POST.VERIFY_OTP, { token: OTPToken, OTP: enteredOTP });
        //         const { data } = res_data.data;

        //         if (data) await addUserDetails();
        //         else if (!auto) throw new Error("Failed");
        //     // }
        // } catch (error) {
        //     setLoading(false);
        //     if (!auto) {
        //         console.log(error);
        //         toast.error('Entered OTP is Incorrect');
        //     }
        // }
    };

    // const handleOTPChange = async (index: number, val: number) => {
    //     const newOTP = [...OTP];
    //     newOTP[index] = val;
    //     setOTP(newOTP);
    //     // if (index === 3) verifyOTP(true);
    // };

    // useEffect(() => {
    //     const enteredOTP = ((OTP.join()).replaceAll(',', '')).trim();
    //     console.log(enteredOTP);

    //     if (enteredOTP === '0000') {
    //         setLoading(true);
    //         addUserDetails();
    //     }
    // }, [OTP])

    const maxDate = () => {
        const today = new Date('01/01/2012');
        return today;
    };

    const minDate = () => {
        const today = new Date('01/01/1950');
        return today;
    };

    const handleSubmit = async (ev: any) => {
        setLoading(true);
        ev.preventDefault();

        if (nameErr) return toast.error(nameErr);

        // if (OTPToken) verifyOTP();
        // else if (user.isPhoneVerified) {
        if (user.isCollaborator && !isPassValid) return toast.error('Invalid Password');
        await addUserDetails();
        // }
    };

    // const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    //     const current = e.currentTarget;
    //     if (e.key === "ArrowLeft" || e.key === "Backspace") {
    //         const prev = current.previousElementSibling as HTMLInputElement | null;
    //         prev?.focus();
    //         prev?.setSelectionRange(0, 1);
    //         return;
    //     }

    //     else {
    //         const prev = current.nextSibling as HTMLInputElement | null;
    //         prev?.focus();
    //         prev?.setSelectionRange(0, 1);
    //         return;
    //     }
    // };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = event.target.value;
        // const isPasswordValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*_\-+=`|(){}[\]:;"'<>,.?/]).{8,64}$/.test(
        const isPasswordValid = /^(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*[A-Z])(?=.*[a-z]).{10,}$/.test(
            newPassword
        );
        setIsPassValid(isPasswordValid);
        setPassValidation({
            hasNumber: /\d/.test(newPassword),
            hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
            hasUppercase: /[A-Z]/.test(newPassword),
            hasLowercase: /[a-z]/.test(newPassword),
            isMinLength: newPassword.length >= 10,
        });
        setUser({ ...user, password: newPassword });
    };

    const [nameErr, setNameErr] = useState<string>('');

    const handleName = (name: string) => {
        const nameRegex = /^[^\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]*$/;
        const valid = nameRegex.test(name);

        if (!valid) setNameErr('Invalid Name');
        else setNameErr('');

        setUser({ ...user, name });
    };

    return (
        <section className='let-started'>
            <div className='row'>
                <div className='col-sm-6'>
                    <div className='welcome-lets-started-container d-none d-sm-block d-print-block'>
                        <div className='container'>
                            <div className='cst-mobile-bg '>
                                <div className='right-side-container'>
                                    {/* <h1 className='owow-lets-started'>owow</h1> */}
                                    <img src={Logo_Horizontal_WhiteImage} className='img-fluid owow-lets-started' />

                                    <h1 className='welcome-lete-started' style={{ fontSize: "30px" }}>Welcome 👋</h1>
                                    <h1 className='personal-details-heading' >Let’s get inside you personal details</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className='d-sm-block d-print-block cst-container-bg'>
                        {/* <h1 className='owow d-sm-none mb-4'>owow</h1> */}
                        <div className='text-center'>
                            <img src={Logo_Horizontal_WhiteImage} className='img-fluid d-sm-none pt-4 pb-0 mb-0' />
                        </div>
                        <div className='cst-mobile-bg '>
                            <div className='right-side-container-personal'>
                                <h1 className='welcome-heading personal-details-heading-right-side'>Personal details</h1>
                                <h4 className='heado-para margin-fix-bottam'>We need your personal details to shape your profile like a pro.</h4>
                                <form id='personalForm' onSubmit={handleSubmit}>
                                    <div className="col-12 input-group-lg margin-fix-bottam">
                                        <label>Full name*</label>
                                        <input
                                            type="text"
                                            name='name'
                                            value={user.name}
                                            onChange={(val) => handleName(val.target.value)}
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            required
                                        />
                                        {nameErr !== "" && <p style={{ color: 'red', fontSize: 'medium' }}>{nameErr}</p>}
                                    </div>

                                    {(user.isCollaborator || user.addedById !== 0) && (
                                        <div className="col-12 input-group-lg margin-fix-bottam">
                                            <label>Password*</label>
                                            <input
                                                type={showPassword ? 'text' : 'password'}
                                                onChange={handlePasswordChange}
                                                className="form-control custom-input"
                                                placeholder="Enter Here"
                                                id="password"
                                                required
                                            />
                                            {showPassword ? <BsFillEyeFill onClick={togglePasswordVisibility} /> : <BsFillEyeSlashFill onClick={togglePasswordVisibility} />}

                                            {!isPassValid && (
                                                <div className='password-validation-card p-3 mb-4'>
                                                    <h6 className="text-black checkbox-text">Your password must contain:</h6>
                                                    <div className='form-check'>
                                                        <ul>
                                                            <li>
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.isMinLength} />
                                                                <p className="text-black checkbox-text"> a minimum of 10 character</p>
                                                            </li>
                                                            <li>
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.hasUppercase} />
                                                                <p className="text-black checkbox-text"> an uppercase character</p></li>
                                                            <li>
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.hasLowercase} />
                                                                <p className="text-black checkbox-text"> a lowercase character</p></li>

                                                        </ul>
                                                        <ul>
                                                            <li>
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.hasNumber} />
                                                                <p className="text-black checkbox-text"> a number</p></li>
                                                            <li>
                                                                <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={passValidation.hasSpecialChar} />
                                                                <p className="text-black checkbox-text"> a special character</p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    <div className="col-12 input-group-lg margin-fix-bottam">
                                        <label>Job title*</label>
                                        <input
                                            type="text"
                                            name='jobTitle'
                                            value={user.jobTitle}
                                            onChange={(val) => setUser({ ...user, jobTitle: val.target.value.replace(/[^a-zA-Z0-9]/g, '') })}
                                            id='jobTitle'
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            required
                                        />
                                    </div>
                                    <div className="row mb-0 margin-fix-bottam">
                                        {/* <input
                                            type="date"
                                            name="dob"
                                            value={user.dob}
                                            max={maxDate()}
                                            onChange={(val) => setUser({ ...user, dob: val.target.value })}
                                            id="dob"
                                            className="form-control custom-input"
                                            placeholder="DD-MM-YYYY"
                                            required
                                        /> */}
                                        <div className="col-12 col-md-4 margin-fix-bottam">
                                            <label>Date of birth*</label>
                                            {/* <ReactDatePicker
                                                className='form-control custom-input'
                                                showIcon
                                                value={user.dob?.split('T')[0]}
                                                maxDate={maxDate()}
                                                minDate={minDate()}
                                                onChange={(val) => setUser({ ...user, dob: val?.toISOString() })}
                                                icon="fa fa-calendar"
                                                placeholderText='YYYY-MM-DD'
                                                showYearDropdown
                                                dateFormat="dd MMM yyyy a"
                                             /> */}
                                            <div className='date-picker-input'>
                                                <DatePicker
                                                    className='form-control custom-datepicker'
                                                    disabledDate={d => !d || d.isAfter(maxDate()) || d.isBefore(minDate())}
                                                    suffixIcon={<BsCalendarFill style={{ color: '#492a99' }} />}
                                                    allowClear={false}
                                                    defaultPickerValue={dayjs('12/31/2011')}
                                                    defaultValue={user.dob ? dayjs(user.dob) : undefined}
                                                    onChange={(val) => setUser({ ...user, dob: val?.toISOString() })}
                                                    placeholder='YYYY-MM-DD'
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-8 col-12">
                                            <label>Gender*</label>
                                            <Select
                                                options={genders}
                                                defaultValue={genders.filter((ct) => ct.value == user?.gender)[0]}
                                                onChange={(ev) => setUser({ ...user, gender: ev?.value || '' })}
                                                styles={customStyles}
                                                className="mb-4"
                                                required
                                                placeholder="Select"
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="col-12 input-group-lg ">
                                        <label>Mobile number</label>
                                        <input
                                            type="tel"
                                            name="phone"
                                            value={user.phone}
                                            onChange={(val) => setUser({ ...user, phone: val.target.value })}
                                            id="phone"
                                            className="form-control custom-input"
                                            placeholder="Enter here"
                                            required
                                        />
                                        {!user.isPhoneVerified && <span onClick={() => getOTP()} className='verify'>{verifying ? 'Sending OTP...' : 'Verify'}</span>}
                                    </div> */}
                                    <div>
                                        <label>Mobile Number*</label>
                                        <div className='d-flex' style={{ gap: "12px" }}>
                                            <div className='m-cst-mobile-int'>
                                                <button
                                                    type="button"
                                                    className="phno"
                                                    id="countryCodeDropdown"
                                                    data-bs-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <span id="selectedCountryCode">
                                                        {selectedCountryCode} <img src={selectedCountryFlag} className="img-fluid" />{' '}
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                                                            <path d="M7 8L0 0H14L7 8Z" fill="black" />
                                                        </svg>
                                                    </span>
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="countryCodeDropdown">
                                                    <a className="dropdown-item" href="#" onClick={() => handleCountryCodeChange('+91', CountryIconOne)}>
                                                        <img src={CountryIconOne} className="img-fluid" alt="India" /> +91 (India) <hr />
                                                    </a>
                                                    <a className="dropdown-item" href="#" onClick={() => handleCountryCodeChange('+1', CountryIconFoure)}>
                                                        <img src={CountryIconFoure} className="img-fluid" alt="US" />  +1 (US)
                                                    </a>

                                                </div>
                                            </div>
                                            <div style={{ width: "-webkit-fill-available" }}>
                                                <input
                                                    type="tel"
                                                    className="form-control custom-input"
                                                    name="phone"
                                                    value={user.phone}
                                                    pattern="[0-9]*"
                                                    onChange={(val) => setUser({ ...user, phone: val.target.value.replace(/[^0-9]/g, '') })}
                                                    id="phone"
                                                    placeholder="Enter here"
                                                    minLength={9}
                                                    maxLength={10}
                                                    required
                                                />
                                                {/* {!user.isPhoneVerified && <span onClick={() => getOTP()} className='verify'>Verify</span>} */}
                                            </div>
                                        </div>
                                    </div>

                                    {/* {
                                        OTPToken && (
                                            <div className="col-12 input-group-lg margin-fix-bottam">
                                                <label>Enter OTP sent on your mobile number.</label>
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <div className="position-relative">
                                                        <div id="otp" className="message-inputs d-flex flex-row justify-content-center" style={{ gap: "26px" }}>
                                                            <input
                                                                className="mt-2 text-center form-control rounded"
                                                                onChange={(ev) => handleOTPChange(0, Number(ev.target.value))}
                                                                onKeyUp={handleKeyUp}
                                                                type="text"
                                                                id="first"
                                                                maxLength={1}
                                                            />
                                                            <input
                                                                className="mt-2 text-center form-control rounded"
                                                                onChange={(ev) => handleOTPChange(1, Number(ev.target.value))}
                                                                onKeyUp={handleKeyUp}
                                                                type="text"
                                                                id="second"
                                                                maxLength={1}
                                                            />
                                                            <input
                                                                className="mt-2 text-center form-control rounded"
                                                                onChange={(ev) => handleOTPChange(2, Number(ev.target.value))}
                                                                onKeyUp={handleKeyUp}
                                                                type="text"
                                                                id="third"
                                                                maxLength={1}
                                                            />
                                                            <input
                                                                className="mt-2 text-center form-control rounded"
                                                                onChange={(ev) => handleOTPChange(3, Number(ev.target.value))}
                                                                onKeyUp={handleKeyUp}
                                                                type="text"
                                                                id="fourth"
                                                                maxLength={1}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mt-2">
                                                    <label>Didn’t receive the code? <b style={{textDecorationLine: "underline"}} onClick={() => getOTP()}>Re-Send</b></label>
                                                </div>
                                            </div>
                                        )
                                    } */}

                                    <div className="col-12 text-center margin-fix-bottam">
                                        <button
                                            type='submit'
                                            className='btn create-account-btn p-3 col-12'
                                            style={{ fontSize: "20px" }}
                                            disabled={nameErr !== '' || !user.name || !user.phone || !user.dob || !user.jobTitle || loading}
                                        >{loading ? 'Saving Details...' : 'Next'}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FirstPersonalDetailsComponent